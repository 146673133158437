import { Box, Button, Select, MenuItem, FormControl, InputLabel, FormHelperText, CircularProgress } from "@mui/material"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "../helper/axios";
import { FieldInput } from "./Field";
import { Motion } from "./Motion";
import { toast } from 'react-toastify';
import { useState } from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const validationSchema = Yup.object({
    jenjang: Yup.string().required('Jenjang harus diisi'),
    phoneNumber: Yup.string()
      .matches(/^(08)[0-9]{8,11}$/, 'Nomor telepon tidak valid')
      .required('Nomor telepon harus diisi'),
    nama: Yup.string().required('Nama harus diisi'),
    asal: Yup.string().test('jenjang', 'Invalid Sekolah Asal', function (value) {
        const jenjang = this.resolve(Yup.ref('jenjang'));
        if (jenjang === 'Ma' && !/^(SMP|MTS)/.test(value)) {
            return this.createError({ path: 'asal', message: 'Harus diawali dengan MTS atau SMP' });
        }
        if (jenjang === 'Mts' && !/^(MI|SD)/.test(value)) {
            return this.createError({ path: 'asal', message: 'Harus diawali dengan MI atau SD' });
        }
        if (!/^(MI|SMP|SD|MTS)/.test(value)) {
            return this.createError({ path: 'asal', message: 'Harus diawali dengan MTS, SMP, SD, atau MI' });
        }
        return true;
    }).required('Sekolah Asal harus diisi'),
    gender: Yup.string().required('Jenis Kelamin harus diisi')
  })
  

export const Register = () => {
    const [loading, setLoading] = useState(false);
    const [attempt, setAttempt] = useState(0)
    const handleReg = async (data, action) => {
        try {
            setLoading(true);
            await axios.post('/reg', data)
            setLoading(false);
            toast.success("Register Berhasil");
            action.resetForm()
        } catch (err) {
            setLoading(false);
            setAttempt(attempt + 1)
            toast.error(
                err.response.data || 'Terjadi kesalahan. Silakan coba lagi nanti.'
            );
        }
    }

    const formik = useFormik({
        initialValues: {
          jenjang: '',
          phoneNumber: '',
          nama: '',
          gender: '',
          asal: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, action) => {
          handleReg(values, action)
        },
      });

    return (
        <Motion>
            <Box sx={{ 
                    minHeight: { sm: '70vh', xs: '65vh' },
                    bgcolor:'white',
                    borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                    display: 'flex',
                    justifyContent: 'center',
                    padding: { xs: '20px 0 0', sm: '40px 0 0' }
                }}
            >
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{
                            minWidth: { md: '70vw', sm: '80vw', xs: '90vw'},  
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px'          
                        }}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="jenjang" error={formik.touched.jenjang && Boolean(formik.errors.jenjang)}>Jenjang Pendaftaran</InputLabel>
                                <Select
                                    labelId="Jenjang"
                                    id="jenjang"
                                    label="Jenjang Pendaftaran"
                                    name="jenjang"
                                    value={formik.values.jenjang}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.jenjang && Boolean(formik.errors.jenjang)}
                                >
                                    <MenuItem value='Mts'>MTs / setara SMP</MenuItem>
                                    <MenuItem value='Ma'>MA / setara SMA</MenuItem>
                                </Select>
                                <FormHelperText error={formik.touched.jenjang && Boolean(formik.errors.jenjang)}>
                                    {formik.touched.jenjang && formik.errors.jenjang}
                                </FormHelperText>
                            </FormControl>
                            <FieldInput 
                                name='phoneNumber'
                                formik={formik}
                                label="No. WhatsApp"
                            />
                            <FieldInput 
                                name='nama'
                                formik={formik}
                                label="Nama"
                            />
                            <FormControl fullWidth size="small">
                                <InputLabel id="gender" error={formik.touched.gender && Boolean(formik.errors.gender)}>Jenis Kelamin</InputLabel>
                                <Select
                                    labelId="Jenjang"
                                    id="gender"
                                    label="Jenis Kelamin"
                                    name="gender"
                                    value={formik.values.gender}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                                >
                                    <MenuItem value='L'>Laki-laki</MenuItem>
                                    <MenuItem value='P'>Perempuan</MenuItem>
                                </Select>
                                <FormHelperText error={formik.touched.gender && Boolean(formik.errors.gender)}>
                                    {formik.touched.gender && formik.errors.gender}
                                </FormHelperText>
                            </FormControl>
                            <FieldInput 
                                name='asal'
                                formik={formik}
                                label="Sekolah Asal"
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                type="submit"
                                color='primary'
                                disabled={loading}
                                sx={{textTransform: 'none'}}
                            >
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Daftar Sekarang'}
                            </Button>
                            {
                                attempt > 2 ?
                                    <Button
                                        startIcon={<WhatsAppIcon/>}
                                        variant="text"
                                        sx={{textTransform: 'none'}}
                                        fullWidth
                                    >
                                        <a href="https://wa.me/+6281266001985" target="_blank" rel="noreferrer">
                                            Bantuan
                                        </a>
                                    </Button> 
                                : 
                                    null
                            }
                        </Box>
                    </form>
                </div>
            </Box>
        </Motion>
    )
}