import { Box } from "@mui/material"
import { useState } from "react"
import { Motion } from "../Motion"
import { PaymentSummary } from "./PaymentSummary"

export const Checkout = () => {
    const [cart, setCart] = useState([])
    return (
        <Motion>
            <Box sx={{ 
                minHeight: { sm: '70vh', xs: '65vh' },
                bgcolor:'white',
                borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                display: 'flex',
                justifyContent: 'center',
                padding: { xs: '20px 0 0', sm: '40px 0 0' },
                position: 'relative',
                zIndex: 2
            }}
            >
                <Box sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: { sm: 'row', xs: 'column'},
                    justifyContent: { sm: 'center', xs: 'normal'}
                }}>
                    <PaymentSummary />
                </Box>
            </Box>
        </Motion>
    )
}