import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Tabs)(() => ({
  backgroundImage: `linear-gradient(to right, #AFAFAF 50%, rgba(255,255,255,0) 0%)`,
  backgroundPosition: 'bottom left',
  backgroundSize: '15px 1px',
  backgroundRepeat: 'repeat-x',
  '& .MuiTabs-indicator': {
    backgroundColor: '#171717',
  },
  '& .MuiTabs-flexContainer': {
    gap: '30px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px'
  },
}));

export { Wrapper };
