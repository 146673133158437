import { Box } from "@mui/material"
import { StudentTesting } from "../../components/Portal/testing/student-test/StudentTest"


export const StudentTestPortal = () => {
    return (
        <Box>
            <StudentTesting />
        </Box>
    )
}