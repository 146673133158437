import { Box } from "@mui/material"
import { useParams } from "react-router-dom"
import { BackButton } from "../../components/BackButton"
import { ExamInfo } from "../../components/Account/ExamInfo"

export const ExamInfoPage = () => {
    const { token } = useParams()
    return(
        <Box>
            <BackButton to={`/${token}`} />
            <ExamInfo />
        </Box>
    )
}