import { Box, useTheme } from "@mui/material"
import { Header } from "../components/Header"
import { Menu } from "../components/Menu"
import { withPageViewTracking } from "./GA";

export const HomePage = () => {
    const theme = useTheme();
    const TrackedHomePage = withPageViewTracking(Menu);
    return(
        <Box sx={{ height: '100vh', bgcolor: theme.palette.primary.main }}>
            <Header />
            <TrackedHomePage />
        </Box>
    )
}