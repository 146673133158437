import { Box, Button, TextField, CircularProgress, Divider } from "@mui/material";
import formatRupiah from "../../helper/Rupiah";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../helper/axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NumericFormat } from "react-number-format";
import { FieldInput } from "../Field";

export const PaymentSummary = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const ref_bayar = useRef();
  const { token } = useParams();
  const getData = async () => {
    try {
      const { data } = await axios.get("/reg", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setUser(data);
    } catch (err) {
      console.log(err);
      navigate("/");
    }
  };

  const formik = useFormik({
    initialValues: {
      bayar: "",
      keterangan: "",
    },
    validationSchema: Yup.object().shape({
      bayar: Yup.string()
        .required("Jumlah Bayar wajib diisi")
        .test("maxAmount", "Jumlah Bayar minimal Rp 50.000", function (value) {
          const numericValue = parseFloat(
            value.replace(/[^\d.]/g, "").replaceAll(".", "")
          );
          return numericValue >= 50000;
        }),
      keterangan: Yup.string().required(
        "Keterangan wajib diisi (ex: Pembayaran Uang Masuk Pondok)"
      ),
    }),
    onSubmit: (values, actions) => {
      const numericValue = parseFloat(
        values.bayar.replace(/[^\d.]/g, "").replaceAll(".", "")
      );
      console.log(numericValue);
      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    getData();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: { sm: "50vw", xs: "90vw" },
          marginBottom: "20px",
          gap: "10px",
        }}
      >
        <TextField
          size="small"
          fullWidth
          label="Jumlah Tagihan"
          disabled
          value={formatRupiah(user?.Tagihan || 0)}
        />
        <Divider sx={{ margin: "10px 0" }} />
        <NumericFormat
          customInput={TextField}
          size="small"
          fullWidth
          label="Jumlah Bayar"
          thousandSeparator="."
          decimalSeparator=","
          prefix="Rp "
          isAllowed={(values) => {
            const { floatValue } = values;
            return floatValue < user?.Tagihan;
          }}
          sx={{
            display: user ? "inline" : "none",
          }}
          inputRef={ref_bayar}
          name="bayar"
          value={formik.values.bayar}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.bayar && Boolean(formik.errors.bayar)}
          helperText={formik.touched.bayar && formik.errors.bayar}
        />
        <FieldInput name="keterangan" formik={formik} label="Keterangan" />
        <Button variant="contained" onClick={formik.handleSubmit}>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Buat Pembayaran"
          )}
        </Button>
      </Box>
    </Box>
  );
};
