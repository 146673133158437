import { Box, Button } from "@mui/material"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";

export const BackButton = ({to}) => {
    const navigate = useNavigate()
    return (
        <Box sx={{
            position: 'fixed',
            top: '10px',
            left: '10px',
            zIndex: 3,
            padding: '5px',
        }}>
            <Button 
                startIcon={<ArrowBackIosIcon />} 
                sx={{color: 'white', textTransform: 'none'}}
                variant="text" 
                onClick={() => navigate(to)}
            >
                Kembali
            </Button>
        </Box>
    )
}