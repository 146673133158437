import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

export const MenuNav = ({ desc, icon: Icon, menu }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate()
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (to) => {
        navigate(to)
        setAnchorEl(null);
    }

  return (
    <div>
        <Button 
            startIcon={<Icon />} 
            sx={{color: 'gray', textTransform: 'none'}}
            variant="text"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            {desc}
        </Button>
        {menu.length  === 0 ? null :
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {
                    menu?.map((value) => {
                        return (
                            <MenuItem onClick={() => handleNavigate(value?.to)}>
                                <Typography sx={{
                                    fontSize: '14px',
                                    fontFamily: 'monospace',
                                    color: value.color || 'black'
                                }}>
                                    {value.desc}
                                </Typography>
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        }
    </div>
  );
}