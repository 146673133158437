import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "../../helper/axios"
import dateConvert from "../../helper/dateConvert"
import { Motion } from "../Motion"

export const ExamCard = () => {
    const [user, setUser] = useState({})
    const navigate = useNavigate()
    const { token } = useParams()
    const getData = async () => {
        try {
            const { data } = await axios.get('/reg', {
                headers: {
                  authorization: `Bearer ${token}`,
                },
            })
            setUser(data)
        } catch (err) {
            console.log(err);
            navigate('/')
        }
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <Motion>
            <Box sx={{ 
                minHeight: { sm: '70vh', xs: '65vh' },
                bgcolor:'white',
                borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: { xs: '20px 0', sm: '40px 0' },
                position: 'relative',
                zIndex: 1
            }}
            >
                <Box sx={{
                    display: 'flex',
                    minWidth: { sm: '600px', xs: '90vw' },
                    alignItems: 'center',
                    gap: '10px',
                }}>
                    <Box
                        sx={{
                            width: '60px',
                            height: '60px',
                        }}
                        >
                            <img
                                src="https://darussalamkasomalang.sch.id/images/identities/logo.png"
                                alt="logo"
                                style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                }}
                            />
                    </Box>
                    <Box>
                        <Typography 
                            lineHeight='16px' 
                            fontSize={{ sm: '14px', xs: '12px' }}
                            fontWeight={500}
                            fontFamily='monospace'
                        >
                            Panitia ujian tes
                        </Typography>
                        <Typography 
                            lineHeight='16px' 
                            fontSize={{ sm: '14px', xs: '12px' }}
                            fontWeight={500}
                            fontFamily='monospace'
                        >
                            Pondok Pesantren Darussalam
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '10px 10px 2px',
                    border: '1px solid black',
                    minWidth: { sm: '600px', xs: '90vw' },
                }}>
                    <Box sx={{
                        display: 'flex',
                        height: { sm: '80px', xs: '60px' },
                        borderBottom: '1px solid',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <Typography fontSize={{ sm:'24px', xs: '14px' }} textAlign="center" fontWeight='bold' fontFamily='monospace'>
                            Kartu <br /> Tes Penilaian Kemampuan (TPK)
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                    }}>
                        <Box sx={{
                            padding: '20px',
                            borderRight: '1px solid',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box
                                sx={{
                                    width: { sm: '100px', xs: '75px' },
                                    height: { sm: '100px', xs: '75px' },
                                }}
                                >
                                    <img
                                        src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${user?.id}`}
                                        alt="qr"
                                        style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        }}
                                    />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: { sm: '30px', xs: '15px' },
                            width: '100%',
                            alignItems: 'start'
                        }}>
                            <Typography 
                                fontSize={{ xs: '12px', sm: '16px'}} 
                                fontFamily="monospace"
                            >
                                Nomor Peserta
                            </Typography>
                            <Typography 
                                fontSize={{ xs: '12px', sm: '16px'}} 
                                fontFamily="monospace" marginLeft={{ xs: "10px" ,sm: "30px"}}
                                fontWeight={600}
                                lineHeight="24px"
                            >
                                {user?.id}
                            </Typography>
                            <Typography 
                                fontSize={{ xs: '12px', sm: '16px'}} 
                                fontFamily="monospace"
                            >
                                Nama Peserta
                            </Typography>
                            <Typography 
                                fontSize={{ xs: '12px', sm: '16px'}} 
                                fontFamily="monospace" marginLeft={{ xs: "10px" ,sm: "30px"}}
                                fontWeight={600}
                                lineHeight="24px"
                            >
                                {user?.Nama_Kandidat}
                            </Typography>
                            <Typography 
                                fontSize={{ xs: '12px', sm: '16px'}} 
                                fontFamily="monospace"
                            >
                                Sekolah Asal
                            </Typography>
                            <Typography 
                                fontSize={{ xs: '12px', sm: '16px'}} 
                                fontFamily="monospace" marginLeft={{ xs: "10px" ,sm: "30px"}}
                                fontWeight={600}
                                lineHeight="24px"
                            >
                                {user?.Sekolah_Asal}
                            </Typography>
                            <Typography 
                                fontSize={{ xs: '12px', sm: '16px'}} 
                                fontFamily="monospace"
                            >
                               Nomor Handphone
                            </Typography>
                            <Typography 
                                fontSize={{ xs: '12px', sm: '16px'}} 
                                fontFamily="monospace" marginLeft={{ xs: "10px" ,sm: "30px"}}
                                fontWeight={600}
                                lineHeight="24px"
                            >
                                {user?.No_hp_Kandidat}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid black',
                    width: { sm: '600px', xs: '90vw' },
                    padding: '10px',
                }}>
                    <Typography
                        fontSize={{ xs: '10px', sm: '14px'}} 
                        fontFamily="monospace"
                        fontWeight={600}
                        lineHeight="24px"
                    >
                        Informasi
                    </Typography>
                    <ul style={{ margin: 0, padding: '0 0 0 20px' }}>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Ujian akan dilaksanakan pada tanggal <b>{dateConvert(user.Tgl_Testing)}</b>
                            </Typography>
                        </li>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Ujian bertempat di <b>Lab Komputer</b> Pondok Pesantren Darussalam kasomalang subang
                            </Typography>
                        </li>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Peserta ujian mengenakan seragam / almamater sekolah asal
                            </Typography>
                        </li>
                    </ul>
                    <Typography
                        fontSize={{ xs: '10px', sm: '14px'}} 
                        fontFamily="monospace"
                        fontWeight={600}
                        lineHeight="24px"
                        margin="10px 0 0"
                    >
                        Materi Tes
                    </Typography>
                    <Typography
                        fontSize={{ xs: '8px', sm: '10px'}} 
                        fontFamily="monospace"
                        fontWeight={600}
                        margin="0 0 0 10px"
                    >
                        Tes Berbasis Komputer
                    </Typography>
                    <ul style={{ margin: 0, padding: '0 0 0 40px' }}>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Matematika
                            </Typography>
                        </li>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Bahasa Indonesia
                            </Typography>
                        </li>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Qur'an Tajwid
                            </Typography>
                        </li>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Tes Minat Bakat
                            </Typography>
                        </li>
                    </ul>
                    <Typography
                        fontSize={{xs: '8px', sm: '10px'}} 
                        fontFamily="monospace"
                        fontWeight={600}
                        margin="0 0 0 10px"
                    >
                        Tes Tulis
                    </Typography>
                    <ul style={{ margin: 0, padding: '0 0 0 40px' }}>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Imla (dikte menulis B. Arab)
                            </Typography>
                        </li>
                    </ul>
                    <Typography
                        fontSize={{xs: '8px', sm: '10px'}} 
                        fontFamily="monospace"
                        fontWeight={600}
                        margin="0 0 0 10px"
                    >
                        Tes Lisan
                    </Typography>
                    <ul style={{ margin: 0, padding: '0 0 0 40px' }}>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Baca Al-Qur'an
                            </Typography>
                        </li>
                        <li>
                            <Typography 
                                fontSize={{ xs: '8px', sm: '10px'}} 
                                fontFamily="monospace"
                            >
                                Interview
                            </Typography>
                        </li>
                    </ul>
                </Box>
            </Box>
        </Motion>
    )
}