import { Box } from "@mui/material"
import { Register } from "../../components/Portal/Register"


export const RegisterPortal = () => {
    return (
        <Box>
            <Register />
        </Box>
    )
}