import { Button, CircularProgress, TableCell } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../helper/axios";

const Konfirmasi = ({ id, token, reload, setReload }) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = async (id) => {
    try {
      setLoading(true);
      await axios.post(
        "/portal/confirm/paid",
        { id },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setReload(!reload);
      toast.success("Konfirmasi Berhasil");
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(
        err.response.data || "Terjadi kesalahan. Silakan coba lagi nanti."
      );
    }
  };
  return (
    <Button
      variant="contained"
      onClick={() => onConfirm(id)}
      sx={{ textTransform: "none" }}
      disabled={loading}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : "Konfirmasi"}
    </Button>
  );
};
const Tolak = ({ id, token, reload, setReload }) => {
  const [loading, setLoading] = useState(false);

  const onCancel = async (id) => {
    try {
      setLoading(true);
      await axios.post(
        "/portal/confirm/pending",
        { id },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setReload(!reload);
      toast.success("Pembayaran Berhasil Ditolak");
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(
        err.response.data || "Terjadi kesalahan. Silakan coba lagi nanti."
      );
    }
  };
  return (
    <Button
        variant="outlined"
        onClick={() => onCancel(id)}
        sx={{ textTransform: "none" }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Tolak"}
      </Button>
  );
};

export const ButtomConfirm = ({ id, reload, setReload }) => {
  const token = localStorage.getItem("token");
  return (
    <TableCell style={{ minWidth: 170, gap: "10px", display: "flex" }}>
      <Konfirmasi id={id} token={token} reload={reload} setReload={setReload} />
      <Tolak id={id} token={token} reload={reload} setReload={setReload} />
    </TableCell>
  );
};
