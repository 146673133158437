import { useFormik } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../helper/axios";
import { Motion } from "../../Motion";

export const CheckInTesting = () => {
  const [option, setOption] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const getData = async () => {
    try {
      const { data } = await axios.get(`/portal/siswa-test/0`);
      const opt = data?.rows.map((item) => {
        return item;
      });
      setOption(opt);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOptionChange = (event, value) => {
    setData(value);
  };

  const handleSubmit = async (values, action) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `/portal/confirm-test/0`,
        { id: data?.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(null);
      action.resetForm();
      document.getElementById("combo-box-demo").value = "";
      toast.success("Kedatangan Berhasil");
      setLoading(false);
    } catch (err) {
      toast.error(
        err.response.data || "Terjadi kesalahan. Silakan coba lagi nanti."
      );
      console.log(err);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: data?.id || "",
    },
    onSubmit: (values, actions) => {
      handleSubmit(values, actions);
      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    getData();
  }, [data]);

  return (
    <Motion>
      <Container maxWidth="md">
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: { xs: "20px", sm: "40px" },
            gap: "20px",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "40px",
            }}
          >
            Kedatangan Peserta Testing
          </Box>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            fullWidth
            size="small"
            options={option}
            onChange={handleOptionChange}
            onInputChange={() => setData({})}
            getOptionLabel={(option) => option?.Nama_Kandidat.toUpperCase()}
            getOptionValue={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Nama Siswa" />
            )}
          />
          <TextField
            size="small"
            label="id"
            fullWidth
            disabled
            value={data?.id || "id"}
          />
          <TextField
            size="small"
            fullWidth
            label="Sekolah Asal"
            disabled
            value={data?.Sekolah_Asal || "Sekolah Asal"}
          />
          <TextField
            size="small"
            fullWidth
            label="Tingkat Pendaftaran"
            disabled
            value={
              data?.Tingkat === "Ma"
                ? "Madrasah Aliyah"
                : "Madrasah Tsanawiyah" || "Tingkat Pendaftaran"
            }
          />
          <TextField
            size="small"
            fullWidth
            label="No. Handphone"
            disabled
            value={data?.No_hp_Kandidat || "No. Handphone"}
          />
          <TextField
            size="small"
            fullWidth
            label="Metode Pendaftaran"
            disabled
            value={
              data
                ? data.Bukti_Tf === null
                  ? "Offline"
                  : "Online"
                : "Metode Pendaftaran"
            }
          />
          <TextField
            size="small"
            fullWidth
            label="Sesi Testing"
            disabled
            value={data?.Sesi_Testing || "Sesi Testing"}
          />
          <Button
            variant="contained"
            disabled={!data || formik.isSubmitting || !formik.isValid}
            onClick={formik.handleSubmit}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit Kedatangan"
            )}
          </Button>
        </Box>
      </Container>
    </Motion>
  );
};
