function terbilang(angka) {
    const satuan = ['', 'ribu', 'juta'];
    const angkaHuruf = ['', 'satu', 'dua', 'tiga', 'empat', 'lima', 'enam', 'tujuh', 'delapan', 'sembilan'];
    
    function satuanTerbilang(bilangan) {
        let hasil = '';

        let ratusan = Math.floor(bilangan / 100);
        let puluhan = Math.floor((bilangan % 100) / 10);
        let satuan = bilangan % 10;

        if (ratusan > 1) {
            hasil += angkaHuruf[ratusan] + ' ratus ';
        } else if (ratusan === 1) {
            hasil += 'seratus ';
        }

        if (puluhan === 1 && satuan > 0) {
            hasil += angkaHuruf[satuan] + ' belas ';
        } else if (puluhan > 1) {
            hasil += angkaHuruf[puluhan] + ' puluh ';
            if (satuan > 0) {
                hasil += angkaHuruf[satuan] + ' ';
            }
        } else if (satuan > 0) {
            hasil += angkaHuruf[satuan] + ' ';
        }

        return hasil;
    }

    let result = '';

    if (angka === 0) {
        result = 'nol';
    } else {
        let satuanIndex = 0;

        while (angka > 0) {
            const ratusanRibuan = angka % 1000;

            if (ratusanRibuan > 0) {
                result = satuanTerbilang(ratusanRibuan) + satuan[satuanIndex] + ' ' + result;
            }

            angka = Math.floor(angka / 1000);
            satuanIndex++;
        }
    }

    return result.trim() + ' rupiah';
}

export default terbilang;
