import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import { HeaderAccount } from "../../components/Account/Header"

export const HeaderAccountPage = () => {
    return (
        <Box sx={{ height: '100vh', bgcolor: '#256D70' }}>
            <HeaderAccount />
            <Outlet />
        </Box>
    )
}