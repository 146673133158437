import { Box, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../helper/axios";
import { Motion } from "../Motion";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import InterpreterModeOutlinedIcon from "@mui/icons-material/InterpreterModeOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import dateConvert from "../../helper/dateConvert";
import { Closed } from "./Closed";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(350deg, #256D70 10%, #0F3B3D 50%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(350deg, #256D70 10%, #0F3B3D 50%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient(350deg, #256D70 10%, #0F3B3D 50%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient(350deg, #256D70 10%, #0F3B3D 50%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <MapsHomeWorkOutlinedIcon />,
    2: <DevicesOutlinedIcon />,
    3: <InterpreterModeOutlinedIcon />,
    4: <TaskAltIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ["Kedatangan", "Tes Pengetahuan", "Tes Wawancara", "Selesai"];

export const ExamInfo = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const { token } = useParams();
  const getData = async () => {
    try {
      const { data } = await axios.get("/reg", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setUser(data);
    } catch (err) {
      console.log(err);
      navigate("/");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Motion>
      {user?.Sesi_Testing > 0 ? (
        <Box
          sx={{
            minHeight: { sm: "70vh", xs: "65vh" },
            bgcolor: "white",
            borderRadius: { sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: { xs: "20px 0", sm: "40px 0" },
            position: "relative",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              minWidth: { sm: "60vw", xs: "90vw" },
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Stepper
              alternativeLabel
              activeStep={
                user?.testing === null ? -1 : user.testing?.Step_Testing
              }
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box
            sx={{
              display: "flex",
              minWidth: { sm: "40vw", xs: "90vw" },
              alignItems: "center",
              justifyContent: "center",
              margin: "40px 0 0",
            }}
          >
            <TableContainer>
              <Table aria-label="custom pagination table">
                <TableBody>
                  <TableRow key={1}>
                    <TableCell component="th" scope="row">
                      Hari, Tanggal
                    </TableCell>
                    <TableCell style={{ width: 300 }} align="right">
                      {"Ahad, " + dateConvert(user.Tgl_Testing)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell component="th" scope="row">
                      Sesi
                    </TableCell>
                    <TableCell style={{ width: 300 }} align="right">
                      {user?.Sesi_Testing === 1
                        ? "1 (Satu)"
                        : user?.Sesi_Testing === 2
                        ? "2 (Dua)"
                        : "3 (Tiga)"}
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell component="th" scope="row">
                      Waktu
                    </TableCell>
                    <TableCell style={{ width: 300 }} align="right">
                      {user?.Sesi_Testing === 1
                        ? "08.00 s.d 10.00 WIB"
                        : user?.Sesi_Testing === 2
                        ? "09.30 s.d 11.30 WIB"
                        : "12.30 s.d 14.00 WIB"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        <Closed />
      )}
    </Motion>
  );
};
