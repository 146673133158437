import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { styled } from '@mui/material';

const actions = [
    { icon: <WhatsAppIcon />, name: 'Putri', wa: "https://wa.me/+6281266001985"  },
    { icon: <WhatsAppIcon />, name: 'Putra', wa: "https://wa.me/+6281366001985" },
];

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  '& .MuiSpeedDial-fab': {
    // background: `linear-gradient(to left, #699F00, #FFEE00)`,
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const CSButton = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const handleCs = (wa) => {
        window.open(wa, '_blank')
    }
  return (
    <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
      <Backdrop open={open} />
      <StyledSpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'fixed', bottom: { sm: 16, xs: 80 }, right: 16, zIndex: 4 }}
        icon={<WhatsAppIcon sx={{color: '#fff'}} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleCs(action.wa)}
          />
        ))}
      </StyledSpeedDial>
    </Box>
  );
}