import { Button, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import dateConvert from "../../../helper/dateConvert";
import formatRupiah from "../../../helper/Rupiah";
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from "react-router-dom";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export const TablePaymentPaid = ({rows, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, count}) => {
    const navigate = useNavigate()
    return (
        <>
            <TableContainer >
                <Table aria-label="caption table" >
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ minWidth: 5 }}
                            size="small"
                        >
                            #
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170, }}
                            size="small"
                        >
                            NAMA
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                            TANGGAL
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                            NOMINAL
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                            METODE
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                            
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                    .map((row, index) => {
                        return (
                        <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            <TableCell style={{ minWidth: 5 }} >
                                {index + 1 + (page * 10)}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                {row.kandidat?.Nama_Kandidat}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                {dateConvert(row.createdAt)}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                { formatRupiah(row.Nominal) }
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                {row.Jenis_Pembayaran}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                <Button onClick={() => navigate(`/portal/kwitansi/${row.id}`)}>
                                    <PrintIcon />
                                </Button>
                            </TableCell>
                        </StyledTableRow>
                        );
                    })}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}