import { Box, Button, CircularProgress } from "@mui/material"
import { useFormik } from 'formik';
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import axios from "../helper/axios";
import { FieldInput } from "./Field";
import { Motion } from "./Motion";

const validationSchema = Yup.object({
    phoneNumber: Yup.string()
        .matches(/^(08)[0-9]{8,11}$/, 'Nomor telepon tidak valid')
        .required('Nomor telepon harus diisi'),
});

export const Login = () => {
    const [loading, setLoading] = useState(false);

    const onLogin = async (data) => {
        try {
            setLoading(true);
            await axios.post('/reg/login', data)
            setLoading(false);
            toast.success("Login Berhasil, Link dikirim ke WhatsApp");
        } catch (err) {
            console.log(err);
            setLoading(false);
            toast.error(
                err.response.data || 'Terjadi kesalahan. Silakan coba lagi nanti.'
            );
        }
    }

    const formik = useFormik({
        initialValues: {
          phoneNumber: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, action) => {
          onLogin(values)
          action.resetForm()
        },
      });

    return (
        <Motion>
            <Box sx={{ 
                    minHeight: { sm: '70vh', xs: '65vh' },
                    bgcolor:'white',
                    borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                    display: 'flex',
                    justifyContent: 'center',
                    padding: { xs: '20px 0 0', sm: '40px 0 0' }
                }}
            >
                <div>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={{
                            minWidth: { md: '70vw', sm: '80vw', xs: '90vw'},  
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px'          
                        }}>
                            <FieldInput 
                                name='phoneNumber'
                                formik={formik}
                                label="No. WhatsApp"
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                type="submit"
                                color='primary'
                                sx={{textTransform: 'none'}}
                            >
                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
                            </Button>
                        </Box>
                    </form>
                </div>
            </Box>
        </Motion>
    )
}