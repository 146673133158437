import { useFormik } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import axios from "../../helper/axios";
import { Motion } from "../Motion";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FieldInput } from "../Field";

export const Equipment = () => {
  const [option, setOption] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const getData = async () => {
    try {
      const { data } = await axios.get(`/portal/siswa`);
      const opt = data?.rows.map((item) => {
        return {
          Nama_Kandidat: item.Nama_Kandidat,
          id: item.id,
          kontak: item.No_hp_Kandidat,
          Tagihan: item.Tagihan,
        };
      });
      setOption(opt);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOptionChange = (event, value) => {
    setData(value);
  };

  const handleSubmit = async (values, action) => {
    try {
      setLoading(true);
      const { data } = await axios.post("/portal/equipment", values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(null);
      action.resetForm();
      document.getElementById("combo-box-demo").value = "";
      toast.success("Pembayaran berhasil");
      setLoading(false);
    } catch (err) {
      toast.error(
        err.response.data || "Terjadi kesalahan. Silakan coba lagi nanti."
      );
      console.log(err);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: data?.id || "",
      Ukuran: "",
    },
    validationSchema: Yup.object().shape({
      Ukuran: Yup.string().required("Ukuran Wajib Diiisi"),
    }),
    onSubmit: (values, actions) => {
      handleSubmit(
        { id: data?.id, ukuran: values.Ukuran },
        actions
      );
      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    getData();
  }, [data]);

  return (
    <Motion>
      <Container maxWidth="md">
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: { xs: "20px", sm: "40px" },
            gap: "10px",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "40px",
            }}
          >
            Loket Ukuran Baju
          </Box>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            fullWidth
            size="small"
            options={option}
            onChange={handleOptionChange}
            onInputChange={() => setData({})}
            getOptionLabel={(option) => option?.Nama_Kandidat.toUpperCase()}
            getOptionValue={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Nama Siswa" />
            )}
          />
          <TextField
            size="small"
            label="id"
            fullWidth
            disabled
            value={data?.id || "id"}
          />
          <TextField
            size="small"
            fullWidth
            label="No. Handphone"
            disabled
            value={data?.kontak || "No. Handphone"}
          />
          <Divider sx={{ margin: "10px 0" }} />
          <FieldInput name="Ukuran" formik={formik} label="Ukuran" />
          <Button
            variant="contained"
            disabled={!data || formik.isSubmitting || !formik.isValid}
            onClick={formik.handleSubmit}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Catat Ukuran"
            )}
          </Button>
        </Box>
      </Container>
    </Motion>
  );
};
