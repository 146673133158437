import { Box } from "@mui/material"
import { Payment } from "../../components/Portal/Payment"


export const PaymentPortal = () => {
    return (
        <Box>
            <Payment />
        </Box>
    )
}