import { Box } from "@mui/material"
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SchoolIcon from '@mui/icons-material/School';
import PaymentsIcon from '@mui/icons-material/Payments';
import DomainIcon from '@mui/icons-material/Domain';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { MenuItem } from "../MenuItem";
import { Motion } from "../Motion";
import { useParams } from "react-router-dom";


export const MenuAccount = () => {
    const { token } = useParams()
    return (
        <Motion>
            <Box sx={{ 
                    height: { sm: '70vh', xs: '65vh' },
                    bgcolor:'white',
                    borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                    display: 'flex',
                    justifyContent: 'center',
                    padding: { xs: '20px 0 0', sm: '40px 0 0' }
                }}
            >
                <Box sx={{
                    minWidth: { md: '70vw', sm: '80vw', xs: '90vw'},  
                    display: 'flex',
                    flexDirection: 'column',
                    gap: { sm: '10px', xs: '5px' }           
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: { sm: '10px', xs: '5px' }
                    }}>
                        <MenuItem desc='Kartu TPK' icon={RecentActorsIcon} to="exam-card" />
                        <MenuItem desc='Info TPK' icon={SchoolIcon} to="exam-info" />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: { sm: '10px', xs: '5px' }
                    }}>
                        <MenuItem desc='Tagihan' icon={AddCardIcon} to={`/${token}/close`} />
                        <MenuItem desc='Riwayat Pembayaran' icon={CreditScoreIcon} to={`/${token}/close`} />
                        <MenuItem desc='Pemberkasan' icon={PersonOutlineIcon} to={`/${token}/folder`} />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: { sm: '10px', xs: '5px' }
                    }}>
                        <MenuItem desc='Info Pemukiman' icon={DomainIcon} to={`/${token}/close`} />
                        {/* <MenuItem desc='Pembayaran' icon={AddCardIcon} to={`/${token}/close`} /> */}
                    </Box>
                </Box>
            </Box>
        </Motion>
    )
}