import { TextField } from "@mui/material"

export const FieldInput = ({ label, name, formik, type = 'text', disabled = false }) => {
    return (
        <TextField
            fullWidth
            id={name}
            name={name}
            label={label}
            type={type}
            disabled={disabled}
            size="small"
            autoComplete='off'
            value={formik.values[name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
        />
    )
}