import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import bg from '../../assets/portal.png'
import ManOutlined from "@mui/icons-material/ManOutlined";
import WomanOutlined from "@mui/icons-material/WomanOutlined";
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import RssFeedOutlinedIcon from '@mui/icons-material/RssFeedOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import { Motion } from "../Motion";
import { useEffect, useState } from "react";
import axios from "../../helper/axios";

export const Home = () => {
    const data = useSelector((state) => state.userSlice.value);
    const [report, setReport] = useState({})
    const token = localStorage.getItem("token");
    
    const getData = async () => {
      try {
        const { data } = await axios.get(`/reg/report`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        setReport(data)
      } catch (err) {
        console.log(err);
      }
    };

    useEffect(() => {
        getData()
    }, [])

    return (
        <Motion>
            <Box sx={{ 
                display: 'flex',
                justifyContent: 'center',
                flexDirection: { sm: 'row', xs: 'column' },
                margin: '20px 0 0',
                gap: '20px'
            }}
            >
                <Box sx={{
                    flex: 1,
                }}>
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        padding: { xs: '10px', sm: '20px' },
                        bgcolor: 'white',
                        border: '1px solid #e6e7e9',
                        borderRadius: '5px',
                    }}>
                        
                        <Box
                            sx={{
                                maxWidth: '250px',
                                maxHeight: '250px',
                                flex: 1
                            }}
                        >
                            <img
                                src={bg}
                                alt="bg"
                                style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography fontWeight={400}>Hai ...</Typography>
                            <Typography fontWeight={700} fontSize="24px">{data?.username}</Typography>
                            <Typography fontWeight={100} fontSize="12px">Apakabar Hari ini?</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    flex: 1,
                    display: 'flex',
                    gap: '10px',
                }}>
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}>
                        <Box sx={{
                            bgcolor: 'white',
                            border: '1px solid #e6e7e9',
                            borderRadius: '5px',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                padding: { xs: '5px', sm: '10px' },
                                justifyContent: 'center',
                                borderBottom: '1px solid #e6e7e9',
                            }}>
                                <Typography>Pendaftar Tsanawiyah</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                padding: { xs: '5px', sm: '10px' },
                            }}>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <ManOutlined sx={{
                                        color: '#0F3B3D',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Putra</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.Mts?.L}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <WomanOutlined sx={{
                                        color: '#0F3B3D',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Putri</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.Mts?.P}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <WcOutlinedIcon sx={{
                                        color: '#0F3B3D',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Total</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.Mts?.L + report.Mts?.P}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>  
                        <Box sx={{
                            bgcolor: 'white',
                            border: '1px solid #e6e7e9',
                            borderRadius: '5px',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                padding: { xs: '5px', sm: '10px' },
                                justifyContent: 'center',
                                borderBottom: '1px solid #e6e7e9',
                            }}>
                                <Typography>Pendaftar Aliyah</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                padding: { xs: '5px', sm: '10px' },
                            }}>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <ManOutlined sx={{
                                        color: '#256D70',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Putra</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.Ma?.L}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <WomanOutlined sx={{
                                        color: '#256D70',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Putri</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.Ma?.P}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <WcOutlinedIcon sx={{
                                        color: '#256D70',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Total</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.Ma?.L + report.Ma?.P}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>  
                        <Box sx={{
                            bgcolor: 'white',
                            border: '1px solid #e6e7e9',
                            borderRadius: '5px',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                padding: { xs: '5px', sm: '10px' },
                                justifyContent: 'center',
                                borderBottom: '1px solid #e6e7e9',
                            }}>
                                <Typography>Total Pendaftar</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                padding: { xs: '5px', sm: '10px' },
                            }}>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <GroupOutlinedIcon sx={{
                                        color: '#219C90',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Putra</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.Mts?.L + report.Ma?.L}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <GroupOutlinedIcon sx={{
                                        color: '#219C90',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Putri</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.Mts?.P + report.Ma?.P}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <GroupOutlinedIcon sx={{
                                        color: '#219C90',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Total</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>
                                            {report.Mts?.L + report.Mts?.P + report.Ma?.L + report.Ma?.P}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>  
                        <Box sx={{
                            bgcolor: 'white',
                            border: '1px solid #e6e7e9',
                            borderRadius: '5px',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                padding: { xs: '5px', sm: '10px' },
                                justifyContent: 'center',
                                borderBottom: '1px solid #e6e7e9',
                            }}>
                                <Typography>Metode Pendaftaran</Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                padding: { xs: '5px', sm: '10px' },
                            }}>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <HomeWorkOutlinedIcon sx={{
                                        color: '#219C90',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Offline</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.offline}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <RssFeedOutlinedIcon sx={{
                                        color: '#219C90',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Online</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>{report.online}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}>
                                    <GroupOutlinedIcon sx={{
                                        color: '#219C90',
                                        fontSize: '30px',
                                    }} />
                                    <Box>
                                        <Typography fontSize="14px" fontWeight={500}>Total</Typography>
                                        <Typography fontSize="14px" fontWeight={300}>
                                            {report.offline + report.online}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>  
                    </Box>
                </Box>
            </Box>
        </Motion>
    )
}