import { Box } from "@mui/material"
import { InterviewTest } from "../../components/Portal/testing/InterviewTest"


export const TestInterviewPage = () => {
    return (
        <Box>
            <InterviewTest />
        </Box>
    )
}