import {
  Box,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { TableStudentTesting } from "./TableTesting";
import { Motion } from "../../../Motion";
import axios from "../../../../helper/axios";
import { useEffect, useState } from "react";
import { TablePendingTesting } from "./TablePending";

export const StudentTesting = () => {
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState(true);
  const [gelombang, setGelombang] = useState(1);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(1000);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleStatus = (status) => {
    setStatus(Boolean(status));
  };
  const handleGelombang = (grlombang) => {
    setGelombang(grlombang);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getData = async () => {
    try {
      const { data } = await axios.get(
        `/portal/test?status=${status}&gelombang=${gelombang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      setRows(data?.rows);
      setCount(data?.count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [page, status, gelombang]);

  return (
    <Motion>
      <Container>
        <Box
          sx={{
            margin: "20px 0 100px",
            border: "1px solid #e6e7e9",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              padding: "10px 15px",
              bgcolor: "#fff",
              alignItems: "center",
            }}
          >
            <Typography>Data Testing</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              padding: "10px 15px",
              bgcolor: "#fff",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth size="small">
              <InputLabel id="Status">Status Testing</InputLabel>
              <Select
                label="Status Testing"
                name="Status"
                defaultValue={1}
                onChange={(e) => handleStatus(e.target.value)}
              >
                <MenuItem value={1}>Sudah Testing</MenuItem>
                <MenuItem value={0}>Belum Testing</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="Gelombang">Gelombang Pendaftaran</InputLabel>
              <Select
                label="Gelombang Pendaftaran"
                name="Gelombang"
                defaultValue={1}
                onChange={(e) => handleGelombang(e.target.value)}
              >
                <MenuItem value={1}>Gelombang 1</MenuItem>
                <MenuItem value={2}>Gelombang 2</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {status === true ? (
            <TableStudentTesting
              rows={rows}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              count={count}
              rowsPerPage={rowsPerPage}
            />
          ) : (
            <TablePendingTesting rows={rows} />
          )}
        </Box>
      </Container>
    </Motion>
  );
};
