import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Motion } from "../Motion";
import FieldImage from "./FieldImage";
import { FieldInput } from "../Field";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { Info } from "../Info";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import axios from "../../helper/axios";
import FieldSelect from "./FieldSelect";
import { useParams } from "react-router-dom";
import IMG_URL from "../../helper/img";
import { toast } from "react-toastify";
import { Closed } from "./Closed";

const validationSchema = Yup.object({
  Nama_ayah: Yup.string().required("nama wajib diisi"),
  Pekerjaan_ayah: Yup.string().required("pekerjaan wajib diisi"),
  Pendapatan_ayah: Yup.string().required("pendapatan wajib diisi"),
  Nama_ibu: Yup.string().required("nama wajib diisi"),
  Pekerjaan_ibu: Yup.string().required("pekerjaan wajib diisi"),
  Pendapatan_ibu: Yup.string().required("pendapatan wajib diisi"),
  NISN: Yup.string()
    .required("nisn wajib diisi")
    .matches(/^\d+$/, "nisn harus berupa angka")
    .length(10, "nisn harus 10 karakter"),
  kk_img: Yup.mixed()
    .required("Kartu Keluarga wajib diisi")
    .test("fileSize", "Ukuran file terlalu besar, maksimal 1MB", (value) => {
      return value && value.size <= 1024 * 1024;
    }),
});

export const FolderComp = () => {
  // const data = [{ desc: "Kartu Keluarga", folder: "kk", img: "" }];
  const { token } = useParams();
  const [image, setImage] = useState(null);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [pekerjaan, setPekerjaan] = useState([]);
  const [pendapatan, setPendapatan] = useState([]);

  const handleUpload = async (data) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", data.kk_img);
      delete data.kk_img;
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }

      await axios.post(`/reg/folder`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success("Berkas berhasil dikirim");
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(
        err.response.data || "Terjadi kesalahan. Silakan coba lagi nanti."
      );
    }
  };

  const getData = async () => {
    try {
      const { data } = await axios.get("/options");
      const res = await axios.get("/reg", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setUser(res.data);
      console.log(res.data);
      setPekerjaan(data.pekerjaan);
      setPendapatan(data.pendapatan);
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      Nama_ayah: user.kandidat_siswa?.siswa_ayah?.Nama || "",
      NISN: user.kandidat_siswa?.NISN || "",
      Pekerjaan_ayah: user.kandidat_siswa?.siswa_ayah?.pekerjaanId || "",
      Pendapatan_ayah: user.kandidat_siswa?.siswa_ayah?.pendapatanId || "",
      Nama_ibu: "",
      Pekerjaan_ibu: user.kandidat_siswa?.siswa_ibu?.pekerjaanId || "",
      Pendapatan_ibu: user.kandidat_siswa?.siswa_ibu?.pendapatanId || "",
      kk_img: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, action) => {
      handleUpload(values);
      action.resetForm();
      setImage(null);
    },
  });

  useEffect(() => {
    getData();
  }, []);

  return (
    <Motion>
      {user.NPS ? (
        <Box
          sx={{
            minHeight: { sm: "70vh", xs: "65vh" },
            bgcolor: "white",
            borderRadius: { sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: { xs: "20px 0", sm: "40px 0" },
            position: "relative",
            zIndex: 1,
          }}
        >
          <Info desc="Pemberkasan">
            <Box
              display="flex"
              flexDirection="column"
              sx={{ margin: "20px 0 0 0" }}
            >
              <form onSubmit={formik.handleSubmit}>
                <Box
                  sx={{
                    minWidth: { md: "60vw", sm: "70vw", xs: "80vw" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Biodata Siswa
                  </Typography>
                  <FieldInput
                    name="NISN"
                    formik={formik}
                    label="NISN (nomor induk siswa nasional)"
                    disabled={user.Status_Berkas}
                  />
                  <Typography sx={{ fontWeight: "bold" }}>
                    Biodata Ayah
                  </Typography>
                  <FieldInput
                    name="Nama_ayah"
                    formik={formik}
                    label="Nama Lengkap"
                    disabled={user.Status_Berkas}
                  />
                  <FieldSelect
                    formik={formik}
                    id={"Pekerjaan_ayah"}
                    label={"Pekerjaan Ayah"}
                    option={pekerjaan}
                    item="pekerjaan"
                    disabled={user.Status_Berkas}
                  />
                  <FieldSelect
                    formik={formik}
                    id={"Pendapatan_ayah"}
                    label={"Pendapatan Ayah"}
                    option={pendapatan}
                    item="pendapatan"
                    disabled={user.Status_Berkas}
                  />
                  <Typography sx={{ fontWeight: "bold" }}>
                    Biodata Ibu
                  </Typography>
                  <FieldInput
                    name="Nama_ibu"
                    formik={formik}
                    label="Nama Lengkap"
                    disabled={user.Status_Berkas}
                  />
                  <FieldSelect
                    formik={formik}
                    id={"Pekerjaan_ibu"}
                    label={"Pekerjaan Ibu"}
                    option={pekerjaan}
                    item="pekerjaan"
                    disabled={user.Status_Berkas}
                  />
                  <FieldSelect
                    formik={formik}
                    id={"Pendapatan_ibu"}
                    label={"Pendapatan Ibu"}
                    option={pendapatan}
                    item="pendapatan"
                    disabled={user.Status_Berkas}
                  />
                  <Typography sx={{ fontWeight: "bold" }}>Berkas</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "gray",
                        fontSize: "14px",
                        alignSelf: "start",
                      }}
                    >
                      Kartu Keluarga
                    </Typography>
                    {image || user?.Status_Berkas ? (
                      <Box
                        sx={{
                          padding: { sm: "20px", xs: "15px" },
                          display: "flex",
                          justifyContent: "center",
                          border: "1px dashed",
                          color: "gray",
                          borderRadius: "5px",
                          cursor: "pointer",
                          maxWidth: "60%",
                          position: "relative",
                        }}
                      >
                        <img
                          src={
                            user?.Status_Berkas
                              ? `${IMG_URL}public/${user?.kandidat_siswa.berkas[0].url}`
                              : URL.createObjectURL(image)
                          }
                          alt="Kartu Keluarga"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "",
                          }}
                        />
                        <Box
                          onClick={() => {
                            setImage(null);
                            formik.setFieldValue("kk_img", null);
                          }}
                          sx={{
                            position: "absolute",
                            top: "-12px",
                            right: "-10px",
                            bgcolor: "white",
                            width: "20px",
                            height: "20px",
                            display: user?.Status_Berkas ? "none" : "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            color: "orange",
                          }}
                        >
                          <HighlightOffOutlinedIcon />
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <Box
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                          sx={{
                            padding: { sm: "20px", xs: "15px" },
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px dashed",
                            color:
                              formik.errors.kk_img && formik.touched.kk_img
                                ? "red"
                                : "gray",
                            borderRadius: "5px",
                            cursor: "pointer",
                            gap: "10px",
                          }}
                        >
                          <CameraAltOutlinedIcon /> Upload Kartu Keluarga
                        </Box>
                        <input
                          type="file"
                          id="file-input"
                          accept=".png, .jpg, .jpeg"
                          style={{ display: "none", zIndex: 2 }}
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                            formik.setFieldValue("kk_img", e.target.files[0]);
                          }}
                        />
                      </>
                    )}
                    {formik.errors.kk_img && formik.touched.kk_img ? (
                      <FormHelperText error>
                        {formik.errors.kk_img}
                      </FormHelperText>
                    ) : null}
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    color="primary"
                    sx={{ textTransform: "none" }}
                    disabled={user.Status_Berkas}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          </Info>
        </Box>
      ) : (
        <Closed />
      )}
    </Motion>
  );
};
