import { Box } from "@mui/material"
import { CheckInTesting } from "../../components/Portal/testing/CheckIn"


export const TestCheckPage = () => {
    return (
        <Box>
            <CheckInTesting />
        </Box>
    )
}