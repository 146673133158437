import { Box,
} from "@mui/material"
import CardFaq from "./CardFaq"
import { Info } from "./Info"
import { Motion } from "./Motion"

export const Faq = () => {
    return (
        <Motion>
            <Box sx={{ 
                minHeight: { sm: '70vh', xs: '65vh' },
                bgcolor:'white',
                borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                display: 'flex',
                justifyContent: 'center',
                padding: { xs: '20px 0 0', sm: '40px 0 0' },
                position: 'relative',
                zIndex: 2
            }}
            >
                <Info desc="FAQ - Penerimaan Santri Baru (PSB)">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <CardFaq
                        title="Apakah No. Handphone pendaftar wajib ?"
                        description="Benar, isian no handphone pendaftar wajib diisi dan harus menggunakan no. handphone yang sudah diintegrasikan (terdaftar) pada aplikasi WhatsApp"
                        />
                        <CardFaq
                        title="Apakah No. Handphone pendaftar wajib ?"
                        description="Benar, isian no handphone pendaftar wajib diisi dan harus menggunakan no. handphone yang sudah diintegrasikan (terdaftar) pada aplikasi WhatsApp"
                        />
                    </Box>
                </Info>
            </Box>
        </Motion>
    )
}