import {
    Accordion,
    AccordionSummary,
    Box,
    Typography,
    AccordionDetails,
  } from '@mui/material';
  import ControlPointIcon from '@mui/icons-material/ControlPoint';
  import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
  import { useState } from 'react';
  import ReactPlayer from 'react-player';
  
  const CardTutor = ({ title, link }) => {
    const [isOpen, setIsOpen] = useState(false);
  const [isPlayVideo, setIsPlayVideo] = useState(false);
  
    return (
      <Accordion
        onChange={() => setIsOpen(!isOpen)}
        sx={(theme) => ({
          backgroundColor: 'transparent',
          boxShadow: 'none',
          marginBottom: '10px',
          borderRadius: '3px',
          minWidth: { md: '65vw', sm: '80vw', xs: '90vw'},  
        })}
      >
        <AccordionSummary
          expandIcon={
            isOpen ? (
              <RemoveCircleOutlineIcon />
            ) : (
              <ControlPointIcon />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: { fontSize: '14px' },
            })}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
            }}
            >
                <Box
                    sx={{
                    border: '1px solid gray',
                    borderRadius: '10px',
                    padding: { xs: '5px', sm: '10px' },
                    width: { xs: '95%', md: '75%' },
                    height: { xs: '200px', sm: '450px' },
                    }}
                >
                    <ReactPlayer
                    width="100%"
                    height="100%"
                    url={link}
                    playing={isPlayVideo}
                    onStart={() => setIsPlayVideo(false)}
                    onPause={() => setIsPlayVideo(false)}
                    onReady={() => setIsPlayVideo(false)}
                    controls
                    />
                </Box>
            </Box>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  export default CardTutor;
  