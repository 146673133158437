import { Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const MenuItem = ({desc, icon: Icon, to = '/'}) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(to)
    }

    return (
        <Box sx={{
            background: '#256D70',
            flex: 1,
            minHeight: { sm: '80px', xs: '60px'},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            borderRadius: '10px',
            cursor: 'pointer',
        }}
        onClick={handleClick}
        >
            {Icon && <Icon sx={{ fontSize: { sm: '50px', xs: '30px' } }} />}
            <Typography fontSize={{ sm: '12px', xs: '10px' }}>
                {desc}
            </Typography>
        </Box>
    )
}