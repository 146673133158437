import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { TableRegistered } from './TableRegistered';
import { Motion } from '../../Motion';
import axios from '../../../helper/axios';
import { useEffect, useState } from 'react';


export const Registered = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value, 10);
    setPage(0);
  };

  const getData = async () => {
    try {
        const { data } = await axios.get(`/portal/siswa?page=${page + 1}&limit=10`) 
        setRows(data?.rows)
        setCount(data?.count)
    } catch (err) {
        console.log(err);
    }
  }

  useEffect(() => {
    getData()
  }, [ page ])

  return (
    <Motion>
        <Container>
            <Box sx={{
                margin: '20px 0 100px',
                border: '1px solid #e6e7e9'
            }}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '10px 15px',
                    bgcolor: '#fff',
                    alignItems: 'center'
                }}>
                    <Typography>Data Pendaftar</Typography>
                </Box>
                {/* <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px',
                    padding: '10px',
                    bgcolor: '#fff',
                    flexWrap: { xs: 'wrap', sm: 'nowrap'},
                }}>
                    <TextField fullWidth sx={{minWidth: '100px'}} label="Nama" size='small' />
                    <FormControl fullWidth sx={{minWidth: '100px'}} size="small">
                        <InputLabel id="jenjang" >~ pilih jenjang ~</InputLabel>
                        <Select
                            label="~ pilih jenjang ~"
                        >
                            <MenuItem value='Mts'>MTs / setara SMP</MenuItem>
                            <MenuItem value='Ma'>MA / setara SMA</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{minWidth: '100px'}} size="small">
                        <InputLabel id="jenjang">- pilih status -</InputLabel>
                        <Select
                            label="- pilih status -"
                        >
                            <MenuItem value='Mts'>MTs / setara SMP</MenuItem>
                            <MenuItem value='Ma'>MA / setara SMA</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant='contained' sx={{minWidth: '100px'}} >Search</Button>
                    <Button variant='outlined' sx={{minWidth: '100px'}}>Reset</Button>
                </Box> */}
                <TableRegistered
                    rows={rows}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    count={count}
                    rowsPerPage={rowsPerPage}
                />
            </Box>
        </Container>
    </Motion>
  );
}