import { Box, Button, Container } from "@mui/material"
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import { MenuNav } from "./Menu";
import { AvatarComp } from "./Avatar";
import { Wrapper } from "./Tab";
import { useNavigate } from "react-router-dom";

const menu = [
    { desc: 'Loket PSB', icon: AccountBoxOutlinedIcon, item: [
        { desc: 'Loket pendaftaran', to: '/portal/register' },
        { desc: 'Menunggu konfirmasi', to: '/portal/payment-register' },
        { desc: 'Menunggu pembayaran', to: '/portal/pending-register', color: 'orange' },
        { desc: 'Data pendaftar', to: '/portal/student-register', color: 'green' },
    ] },
    { desc: 'Loket Testing', icon: AutoStoriesOutlinedIcon, item: [
        { desc: 'Kedatangan', to: '/portal/test-check' },
        { desc: 'Test Pengetahuan', to: '/portal/test-knowledge', color: 'orange' },
        { desc: 'Test Wawancara', to: '/portal/test-interview', color: 'green' },
        { desc: 'Data Testing', to: '/portal/test-student' },
    ] },
    { desc: 'Loket Bendahara', icon: SellOutlinedIcon, item: [
        { desc: 'Bendahara', to: '/portal/payment' },
        { desc: 'Menunggu Persetujuan', to: '/portal' },
        { desc: 'Data Pembayaran', to: '/portal/student-payment', color: 'green' },
        { desc: 'Ukuran Baju', to: '/portal/equipment', color: 'orange' },
    ] },
]

export const Navbar = () => {
    const navigate = useNavigate()
    return (
        <Box>
            <Box
            sx={{
                display: 'flex',
                height: '50px',
            }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            height: '40px',
                            width: '150px',
                            display: { sm: 'flex', xs: 'none' },
                        }}
                    >
                        <img
                            src="https://darussalamkasomalang.sch.id/images/identities/logo_wide.png"
                            alt="logo"
                            style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            }}
                        />
                    </Box>
                    <Box>
                        <AvatarComp />
                    </Box>
                </Container>
            </Box>
            <Box
            sx={{
                display: 'flex',
                bgcolor: 'white',
                height: '50px',
            }}
            >
                <Container>
                    <Wrapper
                        variant="scrollable"
                        scrollButtons={false}
                    >
                        <Button 
                            startIcon={<HomeOutlinedIcon />} 
                            sx={{color: 'gray', textTransform: 'none'}}
                            onClick={() => navigate('/portal')}
                        >
                            Beranda
                        </Button>
                        {menu.map((item, idx) => {
                            return (
                                <MenuNav desc={item.desc} icon={item.icon} menu={item.item}/>
                            )
                        })}
                    </Wrapper>
                </Container>
            </Box>
        </Box>
    )
}