import { Box } from "@mui/material"
import { Registered } from "../../components/Portal/registered/Registered"


export const RegisteredPortal = () => {
    return (
        <Box>
            <Registered />
        </Box>
    )
}