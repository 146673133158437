import { Box } from "@mui/material"
import { useParams } from "react-router-dom"
import { Checkout } from "../../components/Account/Checkout"
import { BackButton } from "../../components/BackButton"

export const CheckoutPage = () => {
    const { token } = useParams()
    return(
        <Box>
            <BackButton to={`/${token}`} />
            <Checkout />
        </Box>
    )
}