import {
  Container,
  Box,
  Typography,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import axios from "../../helper/axios";
import dateConvert from "../../helper/dateConvert";
import formatRupiah from "../../helper/Rupiah";
import terbilang from "../../helper/terbilang";
import { Motion } from "../Motion";

export const Kwitansi = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const { data } = await axios.get(`/portal/payment/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    getData();
  }, []);

  return (
    <Motion>
      <Container>
        <Box
          ref={componentRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "2480px",
            padding: "0 50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
              padding: "10px 30px",
            }}
          >
            <Box flex={1}>
              <Typography
                fontSize="14px"
                fontWeight={500}
                fontFamily="monospace"
                flex={1}
                textAlign="center"
              >
                PONDOK PESANTREN DARUSSALAM
              </Typography>
              <Typography
                fontSize="14px"
                fontWeight={500}
                fontFamily="monospace"
                flex={1}
                textAlign="center"
              >
                KASOMALANG SUBANG INDONESIA
              </Typography>
            </Box>
            <Box
              sx={{
                width: "60px",
                height: "60px",
              }}
            >
              <img
                src="https://darussalamkasomalang.sch.id/images/identities/logo.png"
                alt="logo"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
            //   gap="5px"
              ml="10px"
              justifyContent='center'
            >
              <Typography
                fontSize="12px"
                fontWeight={500}
                fontFamily="monospace"
              >
                Nomor : {data.id}
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={500}
                fontFamily="monospace"
              >
                Tanggal : {dateConvert(data.createdAt)}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Typography
            fontSize="20px"
            fontWeight={500}
            fontFamily="monospace"
            flex={1}
            textAlign="center"
            margin='10px 0'
          >
            KWITANSI TANDA TERIMA UANG
          </Typography>
          <Grid container spacing={1} sx={{ padding: "20px" }}>
            <Grid item xs={5}>
              <Typography fontSize="12px" fontFamily="monospace">
                Sudah terima dari : Nama Siswa:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography
                fontSize="12px"
                fontFamily="monospace"
                fontWeight={700}
              >
                {data.kandidat?.Nama_Kandidat}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize="12px" fontFamily="monospace">
                Banyaknya Uang:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontSize="12px" fontFamily="monospace">
                {terbilang(data.Nominal)}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography fontSize="12px" fontFamily="monospace">
                Untuk Pembayaran:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography fontSize="12px" fontFamily="monospace">
                Pembayaran Uang Masuk Pondok
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              padding: '30px 0'
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  padding: "10px 30px",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                  fontFamily: "monospace",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                {formatRupiah(data.Nominal || 0)}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              <Typography
                sx={{
                  padding: "0px 30px",
                  borderTop: "1px solid black",
                  fontFamily: "monospace",
                  fontSize: "14px",
                }}
              >
                Bendahara Pondok
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            padding: "20px 100px",
            gap: "10px",
          }}
        >
          <Button
            onClick={() => navigate("/portal/student-payment")}
            variant="outlined"
          >
            Kembali
          </Button>
          <Button onClick={handlePrint} variant="contained">
            Print
          </Button>
        </Box>
      </Container>
    </Motion>
  );
};
