import { Box,
    TableContainer,
    TableBody,
    TableCell,
    Table,
    TableHead,
    TableRow,
    Typography
} from "@mui/material"
import { Info } from "./Info"
import { Motion } from "./Motion"

export const Schedule = () => {

    const data = [
        {desc: 'Gelombang 1', date: "16 November 2023 s.d 21 Januari 2024"},
        {desc: 'Test Penilaian Kemampuan Gel. 1', date: "Ahad, 28 Januari 2024"},
        {desc: 'Gelombang 2', date: "4 April s.d 16 Juni 2024"},
        {desc: 'Test Penilaian Kemampuan Gel. 2', date: "Ahad, 23 Juni 2024"},
        {desc: 'Jadwal Mukim Santri Baru di PP Darussalam Kasomalang', date: "10 Juli s.d 14 Juli 2024"},
    ]
    return (
        <Motion>
            <Box sx={{ 
                minHeight: { sm: '70vh', xs: '65vh' },
                bgcolor:'white',
                borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                display: 'flex',
                justifyContent: 'center',
                padding: { xs: '20px 0 0', sm: '40px 0 0' },
                position: 'relative',
                zIndex: 2
            }}
            >
                <Info desc="Jadwal Penerimaan Santri Baru">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <Typography sx={{ fontSize: { xs: '10px', sm: '12px'}, fontWeight: 'bold' }}>No</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: { xs: '10px', sm: '12px'}, fontWeight: 'bold' }}>Jadwal Kegiatan</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography sx={{ fontSize: { xs: '10px', sm: '12px'}, fontWeight: 'bold' }}>Waktu</Typography>
                                    </TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {data.map((item, idx) => (
                                    <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="center">
                                            <Typography sx={{ fontSize: { xs: '10px', sm: '12px'} }}>{idx + 1}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ fontSize: { xs: '10px', sm: '12px'} }}>{item.desc}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography sx={{ fontSize: { xs: '10px', sm: '12px'} }}>{item.date}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'start'
                        }}>
                            <Typography sx={{ fontSize: { xs: '10px', sm: '12px'}, margin: '10px 0' }}>Catatan :</Typography>
                            <Typography sx={{ fontSize: { xs: '10px', sm: '12px'} }}>- Seluruh jadwal diatas dapat berubah sewaktu-waktu.</Typography>
                            <Typography sx={{ fontSize: { xs: '10px', sm: '12px'}, wordWrap: 'break-word' }}>- Pendaftaran dapat dilakukan secara offline (mendaftar langsung di Pondok Pesantren Darussalam) atau melalui online yaitu melalui web resmi Pondok Pesantren Darussalam Kasomalang.</Typography>
                        </Box>
                    </Box>
                </Info>
            </Box>
        </Motion>
    )
}