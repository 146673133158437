import { Box } from "@mui/material"
import { PaidPayment } from "../../components/Portal/student-payment/PaidPayment"


export const PaidPaymentPortal = () => {
    return (
        <Box>
            <PaidPayment />
        </Box>
    )
}