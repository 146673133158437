import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
  } from '@mui/material';
  import ControlPointIcon from '@mui/icons-material/ControlPoint';
  import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
  import { useState } from 'react';
  
  const CardFaq = ({ title, description }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <Accordion
        onChange={() => setIsOpen(!isOpen)}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          marginBottom: '10px',
          borderRadius: '3px',
        }}
      >
        <AccordionSummary
          expandIcon={
            isOpen ? (
              <RemoveCircleOutlineIcon />
            ) : (
              <ControlPointIcon />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: { fontSize: '14px' },
            })}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: { fontSize: '14px' },
            })}
          >
            {description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  export default CardFaq;
  