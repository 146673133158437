import { motion } from 'framer-motion';

export const Motion = ({ children }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 40 }}
            transition={{ duration: 1 }}
        >
            {children}
        </motion.div>
    )
}