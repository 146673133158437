import { Box, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"

export const Info = ({ children, desc }) => {
    return (
        <Box sx={{
            width: { md: '70vw', sm: '80vw', xs: '90vw'},  
            display: 'flex',
            flexDirection: 'column',
            gap: { sm: '10px', xs: '5px' },
            borderRadius: '10px',
            padding: '15px',
            border: '1px solid',
            borderColor: grey[400],
            marginBottom: '50px'
        }}>
            <Box sx={{
                background: 'linear-gradient(350deg, #256D70 10%, #0F3B3D 50%)',
                minHeight: { sm: '50px', xs: '30px' },
                display: 'flex',
                alignItems: 'center',
                padding: '0 20px',
                color: 'white',
                borderRadius: '10px',
            }}>
                <Typography sx={{
                    fontSize: { sm: '14px', xs: '12px' }
                }}
                >
                    {desc}
                </Typography>
            </Box>
                {children}
        </Box>
    )
}