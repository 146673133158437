import { Box, useTheme } from "@mui/material"
import { BackButton } from "../components/BackButton"
import { Header } from "../components/Header"
import { Register } from "../components/Register"
import { withPageViewTracking } from "./GA"
import { Closed } from "../components/Account/Closed"

export const RegisterPage = () => {
    const theme = useTheme()
    const RegisterTrack = withPageViewTracking(Register);
    return(
        <Box sx={{ height: '100vh', bgcolor: theme.palette.primary.main }}>
            <BackButton to={"/"} />
            <Header />
            <RegisterTrack />
            {/* <Closed /> */}
        </Box>
    )
}