import { Box } from "@mui/material"
import { FolderComp } from "../../components/Account/Foldering"
import { BackButton } from "../../components/BackButton"
import { useParams } from "react-router-dom"

export const FolderPage = () => {
    const { token } = useParams()
    return(
        <Box>
            <BackButton to={`/${token}`} />
            <FolderComp />
        </Box>
    )
}