import { Box, Typography } from "@mui/material"
import { Motion } from "../Motion"
import Img from "../../assets/close.png"

export const Closed = () => {
    return (
        <Motion>
            <Box sx={{ 
                minHeight: { sm: '70vh', xs: '65vh' },
                bgcolor:'white',
                borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: { xs: '20px', sm: '40px' },
                position: 'relative',
                zIndex: 2
            }}
            >
                <Typography sx={{
                    fontSize: { sm: '14px', xs: '12px' },
                    fontWeight: 100,
                    fontFamily: 'monospace',
                    alignItems: 'center',
                    color: 'green'
                }}
                >
                    * Fitur belum dibuka
                </Typography>
                <Box
                        sx={{
                            height: { sm: '500px', xs: '300px' },
                            display: 'flex',
                            padding: '20px',
                        }}
                    >
                        <img
                            src={Img}
                            alt="logo"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain'
                            }}
                        />
                </Box>
            </Box>
        </Motion>
    )
}