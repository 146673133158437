import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export const TableRegistered = ({rows, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, count}) => {
    return (
        <>
            <TableContainer >
                <Table aria-label="caption table" >
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ minWidth: 5 }}
                            size="small"
                        >
                            #
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170, }}
                            size="small"
                        >
                            NAMA
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                            JENJANG
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                            SEKOLAH ASAL
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                            KONTAK
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                    .map((row, index) => {
                        return (
                        <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            <TableCell style={{ minWidth: 5 }} >
                                {index + 1 + (page * 10)}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                {row.Nama_Kandidat.toUpperCase()}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                {row.Tingkat}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                {row.Sekolah_Asal}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                {row.No_hp_Kandidat}
                            </TableCell>
                        </StyledTableRow>
                        );
                    })}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}