import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TableStudentTesting = ({
  rows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  count,
}) => {
  return (
    <>
      <TableContainer>
        <Table aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 20 }} size="small">
                NO. ANTRIAN
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                NAMA
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                SESI
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                KEDATANGAN
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                TES PENGETAHUAN
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                TES WAWANCARA
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                >
                  <TableCell style={{ minWidth: 20 }}>{row.id}</TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    {row.kandidat?.Nama_Kandidat.toUpperCase()}
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    {row.kandidat?.Sesi_Testing}
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    {row.Datang_Testing ? (
                      <CheckIcon sx={{ color: "green" }} />
                    ) : null}
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    {row.Test_Pengetahuan ? (
                      <CheckIcon sx={{ color: "green" }} />
                    ) : null}
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    {row.Test_Wawancara ? (
                      <CheckIcon sx={{ color: "green" }} />
                    ) : null}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[1000]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
