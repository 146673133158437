import { Box } from "@mui/material"
import { BackButton } from "../../components/BackButton"
import { Header } from "../../components/Header"
import { ConfirmPayment } from "../../components/Portal/Confirm"

export const ConfirmPaymentPage = () => {
    return(
        <Box sx={{ height: '100vh', bgcolor: '#256D70' }}>
            <BackButton to={"/"} />
            <Header />
            <ConfirmPayment />
        </Box>
    )
}