import { Box, useTheme } from "@mui/material"
import { BackButton } from "../components/BackButton"
import { Header } from "../components/Header"
import { Tutorial } from "../components/Tutorial"
import { withPageViewTracking } from "./GA"

export const TutorialPage = () => {
    const theme = useTheme()
    const TutorialTrack = withPageViewTracking(Tutorial);
    return(
        <Box sx={{ height: '100vh', bgcolor: theme.palette.primary.main }}>
            <BackButton to={"/"} />
            <Header />
            <TutorialTrack />
        </Box>
    )
}