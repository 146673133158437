import { Box } from "@mui/material"
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import LoginIcon from '@mui/icons-material/Login';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Motion } from "./Motion";
import { MenuItem } from "./MenuItem";
import { CSButton } from "./Cs";


export const Menu = () => {
    return (
        <Motion>
            <Box sx={{ 
                    height: { sm: '70vh', xs: '65vh' },
                    bgcolor:'white',
                    borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                    display: 'flex',
                    justifyContent: 'center',
                    padding: { xs: '20px 0 0', sm: '40px 0 0' }
                }}
            >
                <Box sx={{
                    minWidth: { md: '70vw', sm: '80vw', xs: '90vw'},  
                    display: 'flex',
                    flexDirection: 'column',
                    gap: { sm: '10px', xs: '5px' }           
                }}>
                    <Box sx={{
                        display: 'flex',
                        gap: { sm: '10px', xs: '5px' }
                    }}>
                        <MenuItem desc='Pendaftaran' icon={PeopleOutlineIcon} to="/register" />
                        <MenuItem desc='Login' icon={LoginIcon} to="/login" />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: { sm: '10px', xs: '5px' }
                    }}>
                        <MenuItem desc='Jadwal' icon={DateRangeIcon} to="/schedule" />
                        <MenuItem desc='Biaya' icon={PaymentsOutlinedIcon} to="/cost" />
                        <MenuItem desc='Seragam' icon={CheckroomIcon} to="/clothes" />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: { sm: '10px', xs: '5px' }
                    }}>
                        <MenuItem desc='Tutorial' icon={SmartDisplayOutlinedIcon} to="/tutorial" />
                        <MenuItem desc='Petunjuk Pendaftaran' icon={ArticleOutlinedIcon} to="/intruction" />
                        <MenuItem desc='Pertanyaan' icon={LiveHelpOutlinedIcon} to="/faq" />
                    </Box>
                </Box>
            </Box>
            <CSButton />
        </Motion>
    )
}