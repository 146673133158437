import { Box } from "@mui/material"
import { PaymentReg } from "../../components/Portal/register-payment/PaymentReg"


export const PaymentRegPortal = () => {
    return (
        <Box>
            <PaymentReg />
        </Box>
    )
}