import { Box } from "@mui/material"
import { KnowledgeTest } from "../../components/Portal/testing/KnowledgeTest"


export const TestKnowledgePage = () => {
    return (
        <Box>
            <KnowledgeTest />
        </Box>
    )
}