import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const AvatarComp = () => {
    const data = useSelector((state) => state.userSlice.value);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('token')
        navigate('/portal/login')
    }
    return (
        <Box sx={{
            display: 'flex',
            minWidth: '150px',
            padding: '2px',
            borderRadius: '20px',
            alignItems: 'center',
            gap: '10px',
        }}>
            <Avatar 
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}  
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    cursor: 'pointer'
                }}
            />
            <Box sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                <Typography fontSize="14px" lineHeight='14px'>{data?.username}</Typography>
                <Typography fontSize="12px" lineHeight='12px' fontWeight={200}>{data?.role}</Typography>
            </Box>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    
                >
                    <MenuItem 
                        sx={{
                        minWidth: '200px'
                        }}
                        onClick={handleLogout}
                    >
                        Log Out
                    </MenuItem>
                </Menu>
        </Box>
    )
}