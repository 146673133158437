import { Box } from "@mui/material"
import { PendingReg } from "../../components/Portal/register-pending/PaymentReg"


export const PendingRegPortal = () => {
    return (
        <Box>
            <PendingReg />
        </Box>
    )
}