import { Box,
} from "@mui/material"
import CardTutor from "./CardTutor"
import { Info } from "./Info"
import { Motion } from "./Motion"

export const Tutorial = () => {
    return (
        <Motion>
            <Box sx={{ 
                minHeight: { sm: '70vh', xs: '65vh' },
                bgcolor:'white',
                borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
                display: 'flex',
                justifyContent: 'center',
                padding: { xs: '20px 0 0', sm: '40px 0 0' },
                position: 'relative',
                zIndex: 2
            }}
            >
                <Info desc="Video Tutorial">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <CardTutor
                        title="Tutorial Pendaftaran"
                        link='https://www.youtube.com/watch?v=Rt8fWpXJGH0'
                        />
                    </Box>
                </Info>
            </Box>
        </Motion>
    )
}