import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';

ReactGA.initialize('G-D2B83N6P39');

export const withPageViewTracking = (WrappedComponent) => {
    const HOC = (props) => {
      const location = useLocation();
  
      useEffect(() => {
        const page = location.pathname + location.search;
        ReactGA.set({ page });
        ReactGA.pageview(page);
      }, [location]);
  
      return <WrappedComponent {...props} />;
    };
  
    return HOC;
  };