import { Box } from "@mui/material"
import { LoginPortal } from "../../components/Portal/Login"


export const LoginPortalPage = () => {
    return (
        <Box>
            <LoginPortal />
        </Box>
    )
}