import { Box } from "@mui/material"
import { Kwitansi } from "../../components/Portal/kwitansi"


export const KwitansiPortal = () => {
    return (
        <Box>
            <Kwitansi />
        </Box>
    )
}