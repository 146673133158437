import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const FieldSelect = ({ id, option, label, formik, item, disabled = false }) => {
  return (
    <FormControl fullWidth size="small">
      <InputLabel
        id={`${id}-label`}
        error={formik.touched[id] && Boolean(formik.errors[id])}
      >
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        label={label}
        disabled={disabled}
        name={id}
        value={`${formik.values[id]}`}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched[id] && Boolean(formik.errors[id])}
      >
        {option.map((value, idx) => {
          return (
            <MenuItem key={idx} value={value.id}>
              {value[item]}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText error={formik.touched[id] && Boolean(formik.errors[id])}>
        {formik.touched[id] && formik.errors[id]}
      </FormHelperText>
    </FormControl>
  );
};

export default FieldSelect;
