import {
  Chip,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import phoneConvert from "../../../../helper/phoneConvert";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const TablePendingTesting = ({ rows }) => {
  console.log(rows);
  const handleCs = (wa) => {
    window.open(`https://wa.me/${wa}`, "_blank");
  };
  return (
    <>
      <TableContainer>
        <Table aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 5 }} size="small">
                #
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                NAMA
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                JK
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                TINGKAT
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                STATUS
              </TableCell>
              <TableCell style={{ minWidth: 170 }} size="small">
                KONTAK
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell style={{ minWidth: 5 }}>{index + 1}</TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    {row?.Nama_Kandidat?.toUpperCase()}
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    {row.JK === "P" ? "Putri" : "Putra"}
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    {row.Tingkat === "Ma" ? "Aliyah" : "Tsanawiyah"}
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    <Chip
                      label="belum testing"
                      size="small"
                      color="error"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell style={{ minWidth: 170 }}>
                    <Typography
                      onClick={() => handleCs(phoneConvert(row.No_hp_Kandidat))}
                      sx={{
                        color: "blue",
                        fontSize: "14px",
                        textDecorationLine: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {row.No_hp_Kandidat}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
