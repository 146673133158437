import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../style/carousle.css';
import { Pagination, Autoplay, Keyboard, Navigation } from 'swiper/modules';
import { Motion } from './Motion';
import { Box, Button } from '@mui/material';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';

export const Clothes = () => {
    const [gender, setGender] = useState('pa')

    const putra = [
        {url: "https://darussalamkasomalang.sch.id/images/wears/1A.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/2A.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/3A.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/4A.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/5A.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/6A.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/7A.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/8A.png"},
    ]

    const putri = [
        {url: "https://darussalamkasomalang.sch.id/images/wears/1.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/2.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/3.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/4.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/5.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/6.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/7.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/8.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/9.png"},
        {url: "https://darussalamkasomalang.sch.id/images/wears/10.png"},
    ]
  return (
    <Motion>
        <Box sx={{
            bgcolor:'white',
            minHeight: { sm: '70vh', xs: '65vh' },
            borderRadius:{ sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
            display: 'flex',
            justifyContent: 'center',
            padding: { xs: '20px 0 0', sm: '40px 0 0' },
            position: 'relative',
            zIndex: 2
        }}
        >
            <Box sx={{
                maxWidth: { md: '80vw', sm: '85vw', xs: '90vw'},  
                display: 'flex',
                flexDirection: 'column',
                gap: { sm: '10px', xs: '5px' },
                borderRadius: '10px',
                padding: '15px',
                marginBottom: '50px'
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                    marginBottom: '10px',
                }}>
                    <Button
                        variant={gender === 'pa' ? 'contained' : 'outlined'}
                        startIcon={<ManIcon />}
                        onClick={() => setGender('pa')}
                        sx={{
                            textTransform: 'none', 
                            color: gender === 'pa' ? 'white' : 'none'
                        }}
                    >
                        Putra
                    </Button>
                    <Button 
                        variant={gender === 'pi' ? 'contained' : 'outlined'}
                        startIcon={<WomanIcon />}
                        onClick={() => setGender('pi')}
                        sx={{
                            textTransform: 'none', 
                            color: gender === 'pi' ? 'white' : 'none'
                        }}
                    >
                        Putri
                    </Button>
                </Box>
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    keyboard={{
                        enabled: true,
                    }}
                    breakpoints={{
                        960: {
                          slidesPerView: 3,
                        },
                        600: {
                          slidesPerView: 2,
                        },
                        0: {
                          slidesPerView: 1,
                        },
                      }}
                    spaceBetween={30}
                    loop={true}
                    modules={[Pagination, Autoplay, Keyboard, Navigation]}
                    className="mySwiper"
                >
                    {
                        gender === 'pa' ? putra.map((item, idx) => {
                            return (
                                <SwiperSlide key={idx}>
                                    <img src={item.url} alt={`seragam ${idx + 1}`} />
                                </SwiperSlide>
                            )
                        }) 
                    : 
                        putri.map((item, idx) => {
                            return (
                                <SwiperSlide key={idx}>
                                    <img src={item.url} alt={`seragam ${idx + 1}`} />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </Box>
        </Box>
    </Motion>
  );
}
