import { useFormik } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import axios from "../../helper/axios";
import formatRupiah from "../../helper/Rupiah";
import { Motion } from "../Motion";
import { NumericFormat } from "react-number-format";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FieldInput } from "../Field";

export const Payment = () => {
  const [option, setOption] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const ref_bayar = useRef();
  const token = localStorage.getItem("token");

  const getData = async () => {
    try {
      const { data } = await axios.get(`/portal/siswa`);
      const opt = data?.rows.map((item) => {
        return {
          Nama_Kandidat: item.Nama_Kandidat,
          id: item.id,
          kontak: item.No_hp_Kandidat,
          Tagihan: item.Tagihan,
        };
      });
      setOption(opt);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOptionChange = (event, value) => {
    setData(value);
  };

  const handleSubmit = async (values, action) => {
    try {
      setLoading(true);
      const { data } = await axios.post("/portal/payment", values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(null);
      action.resetForm();
      document.getElementById("combo-box-demo").value = "";
      toast.success("Pembayaran berhasil");
      setLoading(false);
      navigate(`/portal/kwitansi/${data.id}`);
    } catch (err) {
      toast.error(
        err.response.data || "Terjadi kesalahan. Silakan coba lagi nanti."
      );
      console.log(err);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      bayar: "",
      promo: "",
      id: data?.id || "",
      keterangan: "",
    },
    validationSchema: Yup.object().shape({
      bayar: Yup.string()
        .required("Jumlah Bayar wajib diisi")
        .test("maxAmount", "Jumlah Bayar minimal Rp 50.000", function (value) {
          const numericValue = parseFloat(
            value.replace(/[^\d.]/g, "").replaceAll(".", "")
          );
          return numericValue >= 50000;
        }),
      keterangan: Yup.string().required(
        "Keterangan wajib diisi (ex: Pembayaran Uang Masuk Pondok)"
      ),
    }),
    onSubmit: (values, actions) => {
      const numericValue = parseFloat(
        values.bayar.replace(/[^\d.]/g, "").replaceAll(".", "")
      );
      handleSubmit(
        {
          bayar: numericValue,
          id: data?.id,
          promo: values.promo,
          keterangan: values.keterangan,
        },
        actions
      );
      actions.setSubmitting(false);
    },
  });

  useEffect(() => {
    getData();
  }, [data]);

  return (
    <Motion>
      <Container maxWidth="md">
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: { xs: "20px", sm: "40px" },
            gap: "10px",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "40px",
            }}
          >
            Loket Pembayaran Bendahara
          </Box>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            fullWidth
            size="small"
            options={option}
            onChange={handleOptionChange}
            onInputChange={() => setData({})}
            getOptionLabel={(option) => option?.Nama_Kandidat.toUpperCase()}
            getOptionValue={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Nama Siswa" />
            )}
          />
          <TextField
            size="small"
            label="id"
            fullWidth
            disabled
            value={data?.id || "id"}
          />
          <TextField
            size="small"
            fullWidth
            label="No. Handphone"
            disabled
            value={data?.kontak || "No. Handphone"}
          />
          <Divider sx={{ margin: "10px 0" }} />
          <TextField
            size="small"
            fullWidth
            label="Jumlah Tagihan"
            disabled
            value={formatRupiah(data?.Tagihan || 0)}
          />
          <NumericFormat
            customInput={TextField}
            size="small"
            fullWidth
            label="Jumlah Bayar"
            thousandSeparator="."
            decimalSeparator=","
            prefix="Rp "
            isAllowed={(values) => {
              const { floatValue } = values;
              return floatValue < data?.Tagihan;
            }}
            sx={{
              display: data ? "inline" : "none",
            }}
            inputRef={ref_bayar}
            name="bayar"
            value={formik.values.bayar}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bayar && Boolean(formik.errors.bayar)}
            helperText={formik.touched.bayar && formik.errors.bayar}
          />
          <FieldInput name="keterangan" formik={formik} label="Keterangan" />
          <FormControl fullWidth size="small">
            <InputLabel
              id="promo"
              error={formik.touched.promo && Boolean(formik.errors.promo)}
            >
              Tipe Promo (optional)
            </InputLabel>
            <Select
              labelId="Promo"
              id="promo"
              label="Tipe Promo (optional)"
              name="promo"
              value={formik.values.promo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.promo && Boolean(formik.errors.promo)}
            >
              <MenuItem value="100">100 orang pertama</MenuItem>
              <MenuItem value="alumni">Keluarga Alumni</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            disabled={!data || formik.isSubmitting || !formik.isValid}
            onClick={formik.handleSubmit}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Buat Pembayaran"
            )}
          </Button>
        </Box>
      </Container>
    </Motion>
  );
};
