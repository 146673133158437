import { Avatar, Box, Button, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import axios from "../../helper/axios"

export const HeaderAccount = () => {
    const [user, setUser] = useState({})
    const navigate = useNavigate()
    const { token } = useParams()
    const { pathname } = useLocation()
    const display = pathname.split('/')[2]
    const getData = async () => {
        try {
            const { data } = await axios.get('/reg', {
                headers: {
                  authorization: `Bearer ${token}`,
                },
            })
            setUser(data)
        } catch (err) {
            console.log(err);
            // toast.error('Link Expired / Invalid Link')
            // navigate('/')
        }
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <Box sx={{
            height: { sm: '30vh', xs: '35vh'},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            zIndex: 0
        }}
        position='sticky'
        top='0'
        >
            <Avatar sx={{
                width: 80,
                height: 80,
                margin: '10px 0',
                bgcolor: '#0F3B3D',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
              }}
            />
            <Typography fontSize={{ sm: '12px', xs: '10px' }}>
                {user?.id}
            </Typography>
            <Typography fontSize={{ sm: '18px', xs: '16px' }} fontWeight='bold'>
                {user?.Nama_Kandidat}
            </Typography>
            <Box sx={{
                display: display ? 'none' : 'flex',
                position: 'fixed',
                top: '10px',
                left: '10px',
                zIndex: 3,
                padding: '5px',
            }}>
                <Button 
                    sx={{color: 'white', textTransform: 'none'}}
                    variant="text" 
                    onClick={() => navigate('/')}
                >
                keluar
            </Button>
            </Box>
        </Box>
    )
}