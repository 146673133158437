import { Chip, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import IMG_URL from "../../../helper/img";
import { ButtomConfirm } from "./ConfirmButton";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export const TablePaymentReg = ({rows, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, count, reload, setReload}) => {
    return (
        <>
            <TableContainer >
                <Table aria-label="caption table" >
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{ minWidth: 5 }}
                            size="small"
                        >
                            #
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170, }}
                            size="small"
                        >
                            NAMA
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                            STATUS
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                            BUKTI PEMBAYARAN
                        </TableCell>
                        <TableCell
                            style={{ minWidth: 170 }}
                            size="small"
                        >
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows
                    .map((row, index) => {
                        return (
                        <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            <TableCell style={{ minWidth: 5 }} >
                                {index + 1 + (page * 10)}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                {row.Nama_Kandidat}
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                <Chip label="menunggu konfirmasi" size="small" color="secondary" variant="outlined" />
                            </TableCell>
                            <TableCell style={{ minWidth: 170 }} >
                                <a href={`${IMG_URL}public/${row?.Bukti_Tf}`} target="_blank" rel="noreferrer">
                                    <Typography sx={{
                                        color: 'blue',
                                        fontSize: '14px',
                                        textDecorationLine: 'underline'
                                    }}>buka gambar</Typography>
                                </a>
                            </TableCell>
                            {/* <TableCell style={{ minWidth: 170, gap: '10px', display: 'flex' }} >
                                <Button 
                                    variant="contained"
                                    onClick={() => onConfirm(row.id)}
                                    sx={{textTransform: 'none'}}
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Konfirmasi'}
                                </Button>
                                <Button 
                                    variant="outlined" 
                                    onClick={() => onCancel(row.id)}
                                    sx={{textTransform: 'none'}}
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Tolak'}
                                </Button>
                            </TableCell> */}
                            <ButtomConfirm id={row.id} reload={reload} setReload={setReload} />
                        </StyledTableRow>
                        );
                    })}
                </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}